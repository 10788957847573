import React, { useState } from 'react';
import style from './Burger.module.css'
import { Link, animateScroll as scroll } from "react-scroll";

const BurgerMenu = () => {
    const [burgerActive, setBurgerActive] = useState(false)
    return (
        <div className={style.burgerMenu}>
            <div onClick={() => setBurgerActive(!burgerActive)} className={burgerActive ? style.menu_btn_active : style.menu_btn}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div className={burgerActive ? style.menu_active : style.menu}>
                <nav className={style.navi}>
                    <ul >
                        <li>
                            <Link
                                className={style.menu_item}
                                to="RatesScroll"
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={800}
                                onClick={() => setBurgerActive(!burgerActive)}>Тарифы</Link>
                        </li>
                        <li>
                            <Link
                                className={style.menu_item}
                                to="CompanyScroll"
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={800}
                                onClick={() => setBurgerActive(!burgerActive)}>О комании</Link>
                        </li>
                        <li>
                            <Link
                                className={style.menu_item}
                                to="FooterScroll"
                                spy={true}
                                smooth={true}
                                offset={-200}
                                duration={800}
                                onClick={() => setBurgerActive(!burgerActive)}>Контакты</Link>
                        </li>

                        <button className={style.button_heder_conect}><a href='tel:+996 558 46 80 85'>Позвонить</a></button>

                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default BurgerMenu;