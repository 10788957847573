import React from 'react';
import style from './company.module.css'
import about_co_img from '../../assets/about_co.png'
const AboutCompany = () => {
    return (

        <section id='CompanyScroll'>
            <div className={style.container}>
                <div className={style.about_company}>
                    <div className={style.about_co_text}>
                        <h2>О компании</h2>

                        <p>iTV.KG образовалась в 2012 году и является одной из первых компаний в Кыргызстане, которая предоставляет услуги IPTV. iTV.KG специализируется на предоставлении Абонентам самых передовых и качественных продуктов для просмотра разнообразного контента. Компания постоянно следит за новейшими технологиями в этой области, чтобы предложить своим Абонентам наилучшее решение для просмотра контента в высоком разрешении. Компания так же заботится о том, чтобы Абоненты могли получить максимальную выгоду от использования телевизионных приставок, и поэтому предоставляет подробную информацию о настройке и использованию устройств. iTV.KG гарантирует своим абонентам высокое качество услуги, быстрое и надежное обслуживание, а также отличный сервис и большие возможности чтобы удовлетворить все потребности и ожидания своих Абонентов.</p>
                        <div id={style.line}></div>
                    </div>
                    <div className={style.about_co_img}>
                        <img src={about_co_img} alt="about_company" />
                    </div>
                </div>
            </div>

        </section>
    );
};

export default AboutCompany;