import React from 'react';
import style from './footer.module.css'


const Footer = () => {
    return (
        <footer className={style.bg} id='FooterScroll'>
            <div className={style.container}>
                <div className={style.boxContact}>
                    <div className={style.boxTelephone}>
                        <h2>Контакты</h2>
                        <a href='tel:+996 (312) 46 80 85' > <p>+996 (312) 46 80 85</p></a>
                        <a href='tel:+996 558 46 80 85' > <p>+996 558 46 80 85</p></a>
                    </div>

                    <div className={style.boxAddress}>
                        <h2>Адрес</h2>
                        <a href='https://go.2gis.com/glt2iv' target="_blank"> <p>г. Бишкек, мкрн. Асанбай 8/2, Магазин "Планета Электроники"</p></a>
                    </div>

                    <div className={style.boxEmail}>
                        <h2>Email</h2>
                        <a href="`mailto:office@itv.kg">
                            <p>office@itv.kg</p></a>
                    </div>

                    <div className={style.boxLicense}>
                        <h2>Лицензия</h2>
                        <p>ГАС при ПКР № 14-1122-КР в области электрической связи</p>
                    </div>
                </div>

            </div>
        </footer>
    );
};

export default Footer;