import React from 'react';
import style from './advantager.module.css'
const Advantager = () => {

    return (

        <div className={style.bg}>
            <div className={style.container}>
                <div className={style.textTop}>
                    <p>ПРЕИМУЩЕСТВА</p>
                </div>
                <div className={style.title}>
                    <h2>наслаждайтесь любимыми фильмами и сериалами</h2>
                </div>
                <div className={style.line}></div>
                <div className={style.textBottom}>
                    <ul>
                        <li>Качественное изображение и звук, вплоть до HD-разрешения и 5.1-канального аудио;</li>
                        <li>Возможность выбора языка озвучки для телеканала;</li>
                        <li>Широкий набор дополнительных услуг, связанных с потреблением контента (Видеоклуб, YouTube, онлайн кинотеатры);</li>
                        <li>Прогноз погоды и курсы валют НБКР;</li>
                        <li>Просмотр своего видео и фото через USB флешку;</li>
                        <li>Большое количество радиостанций всех жанров;</li>
                        <li>Возможность ограничения доступ к определенным каналам с функцией «родительский контроль»;</li>
                        <li>Управление телевизором и приставкой одним пультом;</li>
                    </ul>
                </div>
            </div>

        </div>
    );
};

export default Advantager;