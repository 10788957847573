import './App.css'
import Header from './Components/Header';
import Footer from './Components/Footer/index';
import Quality from './Components/Quality';
import AboutCompany from './Components/aboutCompany';
import Advantager from './Components/Advantager';
import Rates from './Components/Rates';

const App = () => {


  return (
    <>
      <Header />
      <main>
        <Quality />
        <AboutCompany />
        <Advantager />
        <Rates />

      </main>
      <Footer />

    </>
  );
};

export default App;