import React from 'react';
import style from './header.module.css'
import BG from '../../assets/bg.jpg'
import logo from '../../assets/itv.png'
import { Link } from 'react-scroll';
import BurgerMenu from './BurgerMenu';

const Header = () => {
    return (
        <header>
            <div className={style.bg}>
                <img src={BG} alt="bg" />
            </div>
            <div className={style.container}>
                <div className={style.navigation}>

                    <BurgerMenu />
                    <nav className={style.menu}>
                        <ul>
                            <li>
                                <Link
                                    className={style.menu_item}
                                    to="CompanyScroll"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={800}>О комании</Link>
                            </li>
                            <li>
                                <Link
                                    className={style.menu_item}
                                    to="RatesScroll"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={800}>Тарифы</Link>
                            </li>
                            <li>
                                <Link
                                    className={style.menu_item}
                                    to="FooterScroll"
                                    spy={true}
                                    smooth={true}
                                    offset={-200}
                                    duration={800}>Контакты</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className={style.textLeft}>
                    <div className={style.boxLogo}>
                        <img src={logo} alt="logo" />
                    </div>
                    <div className={style.nameCompany}>
                        <h1>iTV.KG</h1>
                    </div>
                    <div className={style.title}>
                        <article>
                            <p>Интерактивное телевидение нового поколения</p>
                        </article>
                    </div>

                </div>
            </div>
        </header >
    );
};

export default Header;