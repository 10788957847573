import React from 'react';
import style from './quality.module.css'
import quality_img from '../../assets/qualityIMG.png'
const Quality = () => {
    return (
        <section id='QualityScroll'>
            <div className={style.container}>
                <h2>Высокая четкость и безграничные возможности</h2>
                <div className={style.quality_text}>
                    <p>Насладитесь невероятным кинематографическим опытом благодаря технологии IPTV.
                        Технология, открывающая для пользователя совершенно потрясающие возможности обычного домашнего телевидения. Теперь можно запросто устроить у себя дома настоящий развлекательный центр!
                        Смотрите лучшие кыргызские, российские и зарубежные телевизионные каналы с высоким качеством цифрового изображения и звука, в том числе в высокой четкости HD.
                    </p>
                </div>
                <div className={style.quality_img}>
                    <img src={quality_img} alt="our_picture_quality" />
                </div>
            </div>
            <div className={style.line}>
            </div>
        </section>
    );
};

export default Quality;