import React from 'react';
import style from './rates.module.css'

const Rates = () => {
    return (
        <div className={style.container}>
            <div className={style.title}>
                <h2 id='RatesScroll'>Тарифы</h2>
            </div>
            <table className={style.table}>
                <thead className={style.thead}>
                    <tr className={style.trHead}>
                        <th>Название</th>
                        <th>Количество телеканалов, до</th>
                        <th>Доступ к YouTube</th>
                        <th>Видеоклуб, более 1500 фильмов в записи</th>
                        <th>Доступ к ресурсам Ts.kg и Oc.kg</th>
                        <th>Радио</th>
                        <th>Стоимость, сом/мес.</th>
                    </tr>
                </thead>
                <tbody className={style.tbody}>
                    <tr className={style.trBody}>
                        <td>Доступный</td>
                        <td>120</td>
                        <td>Есть</td>
                        <td>Есть</td>
                        <td>Есть</td>
                        <td>Есть</td>
                        <td>440</td>
                    </tr>
                </tbody>
                <tbody className={style.tbody}>
                    <tr className={style.trBody}>
                        <td>Лайт</td>
                        <td>260</td>
                        <td>Есть</td>
                        <td>Есть</td>
                        <td>Есть</td>
                        <td>Есть</td>
                        <td>720</td>
                    </tr>
                </tbody>

            </table>


        </div>
    );
};

export default Rates;